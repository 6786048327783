import React from "react";
import tw from "twin.macro";
import { Link } from "react-router-dom";

import { P, A } from "components/typography";
import styled from "styled-components";
const Footer: React.FC = () => (
  <section tw="bg-primary-dark w-full text-white p-8 block lg:flex flex-row justify-between mt-auto">
    <div tw="mb-4 lg:mb-0 flex flex-col justify-center">
      <P.Medium tw="font-bold text-white">DwellSocial</P.Medium>
      <P.Small tw="text-white">Together, we deliver.</P.Small>
    </div>
    <div tw="lg:text-right">
      <LinkList>
        <li tw="list-none">
          <A.OnDark
            href="http://twitter.com/dwellsocial"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-twitter" />
          </A.OnDark>
        </li>
        <li>
          <A.OnDark
            href="http://facebook.com/dwellsocial"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook" />
          </A.OnDark>
        </li>
        <li>
          <A.OnDark
            href="http://instagram.com/dwellsocial"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-instagram" />
          </A.OnDark>
        </li>
        <li>
          <A.OnDark
            href="https://apps.apple.com/us/app/dwellsocial-food-delivery/id1592176024"
            target="_blank"
            rel="noopener noreferrer"
            tw="text-xs"
          >
            Download our iOS app
          </A.OnDark>
        </li>
        <li>
          <A.OnDark
            href="https://dwell.social/giftcards"
            target="_blank"
            rel="noopener noreferrer"
            tw="text-xs"
          >
            Gift Cards
          </A.OnDark>
        </li>
      </LinkList>
      <LinkList tw="text-xs">
        <li tw="list-none">
          <Link to="/terms" component={A.OnDark}>
            Terms
          </Link>
        </li>
        <li>
          <Link to="/privacy" component={A.OnDark}>
            Privacy
          </Link>
        </li>
        <li>
          <A.OnDark
            href="https://food.dwellsocial.com/support/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Support
          </A.OnDark>
        </li>
        <li>
          <A.OnDark
            href="https://food.dwellsocial.com/for-restaurants/"
            target="_blank"
            rel="noopener noreferrer"
            tw="text-xs"
          >
            For Restaurants
          </A.OnDark>
        </li>
        <li>
          <A.OnDark
            href="https://food.dwellsocial.com/faq/?utm_source=DwellSocial&utm_medium=HomepageFAQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            FAQ
          </A.OnDark>
        </li>
      </LinkList>
      <P.Small tw="text-white">Chat Support Hours: 9am - 6:30pm, CST</P.Small>
    </div>
  </section>
);
export default Footer;

const LinkList = styled.ul(
  tw`flex flex-row lg:justify-end gap-6 list-disc mb-2 pl-0`
);
